@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/corner-radius';

.case-group {
  margin-bottom: spacing.$l;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: spacing.$xxs;
  }

  &__modal-subtitle {
    color: light.$on-surface-primary-alternate;
    margin-bottom: spacing.$l;
  }

  &__modal-description {
    margin-bottom: spacing.$m;
  }

  &__modal-list-item {
    display: flex;
    align-items: center;
    padding: spacing.$m 0;
    gap: spacing.$m;

    &:not(:last-child) {
      border-bottom: 1px solid light.$ge-divider-default;
    }

    > dt {
      flex-shrink: 0;
    }

    > dd {
      margin: 0;
    }
  }

  &__empty {
    display: flex;
    align-items: center;
    gap: spacing.$m;
    padding: spacing.$l spacing.$m;
    border-radius: corner-radius.$s;
    background: light.$surface-primary-default;

    > svg {
      flex-shrink: 0;
    }
  }

  &__see-more {
    width: 100%;
    text-align: center;
    margin-top: spacing.$m;
  }
}
